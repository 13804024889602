import { gql, useMutation } from "@apollo/client";
import React, { useCallback, useMemo } from "react";

import { TrackingMutation } from "../../../../../__generated__/graphql";
import { ExternalLink } from "../../../../../shared/components/design-system/Button/Link";
import useSelf from "../../../hooks/useSelf";
import ToggleSwitch from "../../design-system/ToggleSwitch";

export function useTrackingPreference() {
  const { acceptTracking } = useSelf();
  const [optIn, { loading }] = useMutation<TrackingMutation>(gql`
    mutation tracking($allow: Boolean!) {
      acceptTracking(allow: $allow) {
        id
        acceptTracking
      }
    }
  `);

  const toggle = useCallback(
    async (checked: boolean) => {
      await optIn({ variables: { allow: checked } });
    },
    [optIn],
  );

  let checked = acceptTracking === "ALLOW";
  if (loading) checked = !checked;

  return useMemo(() => ({ loading, acceptTracking: checked, toggle }), [checked, loading, toggle]);
}

export default function AnalyticsOptInControl() {
  const { acceptTracking, loading, toggle } = useTrackingPreference();

  return (
    <>
      <ToggleSwitch
        label="Allow analytics cookies to help improve Culture Shift by collecting and reporting on how you use it"
        className="ds-mx-1"
        checked={acceptTracking}
        onChange={toggle}
        loading={loading}
      />
      <p className="ds-mt-5">
        Please read our{" "}
        <ExternalLink to="https://culture-shift.co.uk/wp-content/uploads/2024/08/Culture-Shift-Website-Privacy-Notice.docx.pdf">
          privacy notice
        </ExternalLink>{" "}
        for more information.
      </p>
    </>
  );
}
